<template>
    <div>

        <template>
          <div id="breadcrumb-alignment" class="mb-1">
            <div class="d-flex justify-content-start breadcrumb-wrapper">
              <b-breadcrumb
                :items="breadCrumb()"
              />
            </div>
          </div>
        </template>


        <div v-if="item != null">
            
            <b-card>
                <b-row>

                    <b-col md="12">
                        <span @click="goBack()">
                            <img :src="require('@/assets/images/back.png')" style="width:30px;  margin-right:20px" align="left"/>
                        </span>
                        <h4 class="card-title float-left">Edit Task</h4>
                        
                    </b-col>

                    <b-col md="12">
                        <b-alert v-model="popshowDismissibleAlert" variant="danger" dismissible class="mb-1">
                        <div class="alert-body"> {{error_message}} </div>
                    </b-alert>

                    <b-form @submit.prevent="updateTask">
                        <b-row>
                            <b-col :md="form.periodic != null ? '6' : '12'" >
                                <b-form-group label="Status" class="required">
                                    <b-form-select v-model="form.task_status">
                                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                                    <b-form-select-option value="open">Open</b-form-select-option>
                                    <b-form-select-option value="in_progress">In Progress</b-form-select-option>
                                    <b-form-select-option value="validate" >Validate</b-form-select-option>
                                    <b-form-select-option value="closed">Closed</b-form-select-option>
                                    <b-form-select-option value="failed">Failed</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>

                            <b-col md="6" v-if="form.periodic != null">
                                <b-form-group label="Shift" class="required">
                                    <b-form-select v-model="form.shift">
                                            <b-form-select-option value="" disabled>Select</b-form-select-option>
                                            <b-form-select-option :value="shift" v-for="shift in shifts" :key="shift">{{shift}}</b-form-select-option>
                                    </b-form-select>
                                    
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row v-if="this.form.periodic != null">
                            <b-col md="6">
                                <b-form-group label="PDF Report Date" class="required"> 
                                    <b-form-datepicker
                                        v-model="form.task_date"
                                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                        locale="en"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row>
                            <b-col md="6" >
                                <b-form-group label="Start Date" class="required"> 
                                    <b-form-datepicker
                                        v-model="form.start"
                                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                        locale="en"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="6" >
                                <b-form-group label="End Date" class="required">
                                    <b-form-datepicker
                                        v-model="form.end"
                                        :date-format-options="{ day: 'numeric', month: 'short',year: 'numeric' }"
                                        locale="en"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="mt-1 mb-1">

                          <b-col md="6">
                            
                            <b-form-group
                              label=""
                              class="margin_bottom_zero_mobile"
                            >
                              <b-form-checkbox
                                v-model="form.planned_time_modify"
                                value="yes"
                                unchecked-value="no"
                                class="custom-control-warning"
                                @change="form.plannedStartTime = '00:00'"
                              >
                                Planned Start Time
                              </b-form-checkbox>
                              
                            </b-form-group>
                          </b-col>

                          
                      
                        </b-row>

                        <b-row >
                            <b-col md="6" v-if="form.planned_time_modify == 'yes'">
                                <b-form-group label="Planned Start Time" class="">
                                    <flat-pickr
                                        v-model="form.plannedStartTime"
                                        class="form-control"
                                        :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                    />
                                </b-form-group>
                            </b-col>

                            <!-- <b-col :md="form.planned_time_modify == 'yes' ? 6 : 12" >
                                <b-form-group label="Training Content" class="margin_bottom_zero_mobile">
                                    <b-form-select v-model="form.training">
                                        <b-form-select-option value=''>Select</b-form-select-option>
                                        <b-form-select-option :value="training._id" v-for="training in trainings" :key="training._id">{{training.name}}</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col> -->

                            
                        </b-row>

                        <b-row class="mt-1">
                            <b-col md="6">
                                <b-form-group label="">
                                    <b-form-checkbox 
                                        v-model="form.start_modify"
                                        value="yes"
                                        unchecked-value="no"
                                        class="custom-control-warning"
                                        @change="form.startTime = '00:00'"
                                    >
                                        Start Time
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>

                            <b-col md="6">
                                <b-form-group label="">
                                    <b-form-checkbox
                                        v-model="form.end_modify"
                                        value="yes"
                                        unchecked-value="no"
                                        class="custom-control-warning"
                                        @change="form.endTime = '00:00'"
                                    >
                                        End Time
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row >
                            <b-col md="6" v-if="form.start_modify == 'yes'">
                                <b-form-group label="Start Time" class="">
                                    <flat-pickr
                                        v-model="form.startTime"
                                        class="form-control"
                                        :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                    />
                                </b-form-group>
                            </b-col>

                            <b-col md="6" v-if="form.end_modify == 'yes'">
                                <b-form-group label="End Time" class="">
                                    <flat-pickr
                                        v-model="form.endTime"
                                        class="form-control"
                                        :config="{ enableTime: true,minuteIncrement:1, noCalendar: true, dateFormat: 'H:i'}"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-row>


                        <b-row >
                            <!-- <b-col md="6" >
                                <b-form-group label="Assign User" class="required">
                                    <v-select
                                        v-model="form.crew"
                                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                        multiple
                                        label="full_name"
                                        :options="allCrews"
                                        placeholder="Select"
                                    />

                                </b-form-group>
                            </b-col> -->

                            <b-col md="6" v-if="form.task_status == 'validate' || form.task_status == 'closed'">
                
                                <b-form-group
                                  label="Completed By"
                                  class="required"

                                >
                                  <b-form-select v-model="form.completed_by">

                                    <b-form-select-option value='' disabled>Select</b-form-select-option>
                                    <b-form-select-option :value="user._id" v-for="user in allUsers" :key="user._id">{{user.full_name}}</b-form-select-option>
                                  </b-form-select>
                                    
                                </b-form-group>
                            </b-col>

                            
                        </b-row>

                        <b-row v-if="form.show_group == 'yes'">

                            <b-col md="12">
                                <b-form-group
                                  label="Group"
                                  class="required margin_bottom_zero_mobile"
                                >
                                 
                                  <!-- <vue-simple-suggest
                                    v-model="form.group"
                                    :list="group_array"
                                    :filter-by-query="true"
                                    placeholder="Max 50 characters"
                                    :class="$store.state.appConfig.layout.skin == 'dark' ? 'darkSimpleSuggest' : 'lightSimpleSuggest'"
                                    >
                                    
                                  </vue-simple-suggest> -->
                                  <v-select
                                    v-model="form.group"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :options="group_array"
                                    multiple
                                    placeholder="Select"
                                    :close-on-select=false
                            :clear-on-select=false
                                  />

                                  <p class="text-warning" @click="openGroupModel()">
                                    <feather-icon icon="PlusCircleIcon" />
                                      <span class="align-middle ml-50" style="cursor: pointer">Add Group</span>
                                  </p>
                                </b-form-group>
                              </b-col>

                        </b-row>



                        <b-row class="mt-2" v-if="form.task_status == 'failed' && form.no_photo_reporting == 'yes'">
                            <b-col md="12">
                                <b-form-group label="Reason" class="required">
                                    <b-form-textarea placeholder="upto 500 characters" rows="3" v-model="form.reason_for_yes"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        
                        <b-row class="mt-2" v-if="form.task_status == 'failed' && form.no_photo_reporting == 'no'">
                            <b-col md="12">
                                <b-form-group label="Reason" class="required">
                                    <b-form-textarea placeholder="upto 500 characters" rows="3" v-model="form.reason_for_no"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2 margin_top_zero_mobile" v-if="this.form.periodic != null">
                            <b-col md="12">
                                <b-form-group label="Description" class="required">
                                    <b-form-textarea placeholder="upto 500 characters" rows="3" v-model="form.description"/>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row class="mt-2">
                            <b-col>
                                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="warning" class="mr-1">
                                    Submit
                                </b-button>
                                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" @click="goBack()">
                                    Cancel
                                </b-button>
                            </b-col>
                        </b-row>

                    </b-form>
                    </b-col>

                </b-row> 
                
            </b-card>

        </div>
    </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,BFormDatepicker,BFormCheckbox,BBreadcrumb
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import 'viewerjs/dist/viewer.css'
import Datepicker from 'vuejs-datepicker';
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select';
import VueSimpleSuggest from 'vue-simple-suggest'
import 'vue-simple-suggest/dist/styles.css' // Optional CSS
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

var moment = require('moment-timezone');
export default {
    components: {
        BCard, BButton, BRow, BCol, BAvatar, BAlert, BTable,BMedia,BForm,BFormTextarea,BModal,BBadge,BCardText,BFormInput,BFormGroup,BFormSelect,BFormSelectOption,Datepicker,BFormDatepicker,flatPickr,BFormCheckbox,vSelect,VueSimpleSuggest,DatePicker,BBreadcrumb
    },
  
    data() {
        return {
        allCrews:[],
        supervisors:[],
        // trainings:[],
        clearButton:true,
        item:null,
        showDismissibleAlert:false,
        popshowDismissibleAlert:false,
        error_message:'',
        doc_icon: require('@/assets/images/doc.png'),
        clientSign : {
            image:null,
            name:'',
        },
        supervisorSign : {
            image:null,
            name:'',
        },
        shifts:[],

        form : {
            task_status : '',
            shift:'',
            start:null,
            end:null,
            task_date:null,
            crew:[],
            // training:null,
            description:'',
            periodic:null,
            no_photo_reporting:'no',
            startTime:'',
            endTime:'',
            start_modify:'no',
            end_modify:'no',
            reason_for_yes:'',
            reason_for_no:'',
            plannedStartTime:'',
            completed_by:'',
            planned_time_modify:'no',
            show_group:'no',
            // group:[],
        },
        openedSignModel:null,
        allUsers: [],
        // group_name:'',
        // group_array:[],
        prevRoute: null
        }
    },

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },

    directives: {
        Ripple,
    },

    methods: {
        showAlert() {
                
            Swal.fire({
                position: 'center',
                icon: 'error',
                text: this.error_message,
                showConfirmButton: false,
                timer: 3000
            })
        },

        variantColor(status){
            if (status == 'open') {
            return 'primary';
            }else if(status == 'in_progress'){
            return 'warning'
            }else if(status == 'validate'){
            return 'info'
            }else if(status == 'failed'){
            return 'danger'
            }else{
            return 'success';
            }
        },

        taskDetail(){
            return this.$store.dispatch(POST_API, {
                data:{
                    id:this.$route.params.id,
                },
                api: '/api/defect-request-status-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                } else {
                    
                    this.item = this.$store.getters.getResults.data;

                    
                    this.form.no_photo_reporting = this.item.site.no_photo_reporting;

                    if (this.item.comments.length == 0) {
                        this.item.comments.push({comment:''});
                    }


                    this.allSiteCrews();
                    this.siteUser(this.item.site._id);

                    var shifts = [];

                    this.item.site.shifts.forEach(item => {
                        shifts.push(item.shift);
                    })
                    this.shifts = shifts;
                    
                    // populating data in fields
                    this.popshowDismissibleAlert = false;

                    this.form.reason_for_yes = '';
                    this.form.reason_for_no  = '';

                    this.form.task_status       = this.item.task_status;
                    this.form.shift             = this.item.shift;
                    this.form.start             = this.item.start;
                    this.form.end               = this.item.end;
                    this.form.crew              = this.item.crew;
                    // this.form.training          = this.item.training ? this.item.training._id : '';
                    this.form.description       = this.item.description;
                    this.form.periodic          = this.item.periodic;
                    this.form.startTime         = this.item.startTime;
                    this.form.endTime           = this.item.endTime;
                    this.form.start_modify      = this.item.start_modify;
                    this.form.end_modify        = this.item.end_modify;
                    this.form.plannedStartTime  = this.item.plannedStartTime;
                    this.form.completed_by      = this.item.completed_by ? this.item.completed_by._id : '';
                    this.form.planned_time_modify = this.item.planned_time_modify;
                    // this.form.group = this.item.group;
                    this.form.task_date = this.item.task_date;
                    
                    
                    
                    // if (this.item.group.length > 0) {
                  
                    //   this.form.show_group = 'yes';

                    //   this.taskGroupSuggestion();

                    // }


                    return this.item;
                }
            });
        },

        updateTask(){
            return this.$store.dispatch(POST_API, {
                data:{
                    items:this.form,
                    id:this.$route.params.id,
                },
                api: '/api/update-defect-request-status-detail'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.popshowDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.popshowDismissibleAlert = false;
                    
                    Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Task Updated Successfully.',
                        showConfirmButton: false,
                        timer: 1500
                    }).then((result) => {
                        this.goBack();
                    });

                    this.reason_for_yes = '';
                    this.reason_for_no = '';
                }
            });
        },

        customFormatter(date) {
            return moment(date).format('DD MMM YYYY');
        },

        allSiteCrews(){
            return this.$store.dispatch(POST_API, {
                data:{
                    site:this.item.site,
                    role: ['site_manager', 'site_client']
                },
                api: '/api/all-crew-by-role'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    this.allCrews = data;

                    return this.allCrews;
                }
            });
        },

        goBack(){            
            var filter = {
                keyword:this.$route.params.keyword,
                sortBy: this.$route.params.sortBy,
                sortDirection: this.$route.params.sortDirection,
                site:this.$route.params.site,
                month:this.$route.params.month,
                year:this.$route.params.year,
            };
            this.$router.push({ name: 'defects', params: filter });
        },

        siteUser(site){      
          return this.$store.dispatch(POST_API, {
               data:{
                 site:site,
               },
               api: '/api/all-site-users'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    //window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    var data  = this.$store.getters.getResults.data;
                    
                    this.allUsers = data;

                    return this.allUsers;
                }
            });
        },
        
        breadCrumb(){
            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'Edit Defect Detail'
            }];

            var filter = {
                keyword:this.$route.params.keyword,
                sortBy: this.$route.params.sortBy,
                sortDirection: this.$route.params.sortDirection,
                site:this.$route.params.site,
                month:this.$route.params.month,
                year:this.$route.params.year
            };

            var filter2 = {
                id:this.$route.params.id,
                keyword:this.$route.params.keyword,
                sortBy: this.$route.params.sortBy,
                sortDirection: this.$route.params.sortDirection,
                site:this.$route.params.site,
                month:this.$route.params.month,
                year:this.$route.params.year
            };

            var item = [{
                to:{name:'client-dashboard'},
                text: 'Dashboard',
            },{
                to:null,
                text: 'Service Request'
            },{
                to:{name: 'defects', params: filter},
                text: 'Defects'
            },{
                to:{name: 'view-defect-status', params: filter2},
                text: 'Defect Detail',
            },{
                to:null,
                text: 'Edit Defect Detail',
                active:true
            }];

            return item;
        }
    },
    mounted(){
        this.taskDetail();
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
